







import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
@Component
export default class UserCheck extends Mixins(Mixin) {
  public captcha = null as any;
  public captchaButton = null as null | HTMLElement;
  public type = "";
  public uuid = "";
  public appId = "";

  mounted(): void {
    this.getUUid();
    this.captchaButton = document.getElementById("captcha-button");
  }

  getUUid(): void {
    this.$api.dictionaryApi.performance.aliyunCaptchaType(({ data }) => {
      this.uuid = data.uuid || "";
      this.type = data.server || "";
      this.appId = data.sceneId || "";
      if (this.type === "ALIYUN") {
        this.aliInit();
      } else if (this.type === "TENCENT") {
        this.txInit();
      }
    });
  }

  /**
   * 阿里滑块相关
   * =====start=====
   */
  aliInit(): void {
    window.initAliyunCaptcha({
      SceneId: this.appId, // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
      prefix: "tkcu18", // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
      mode: "popup", // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
      element: "#captcha-element", // 页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
      button: "#captcha-button", // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
      captchaVerifyCallback: this.captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
      onBizResultCallback: this.onBizResultCallback, // 业务请求结果回调函数，无需修改
      getInstance: this.getInstance, // 绑定验证码实例函数，无需修改
      slideStyle: {
        width: 360,
        height: 40,
      }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
      language: "cn", // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
    });
  }
  captchaVerifyCallback(captchaVerifyParam: string): any {
    const params = {
      captchaVerifyParam,
      uuid: this.uuid,
    };
    this.$emit("success", params);
    // 构造标准返回参数
    const verifyResult = {
      captchaResult: true, // 验证码验证是否通过，boolean类型，必选
      bizResult: true, // 业务验证是否通过，boolean类型，可选；若为无业务验证结果的场景，bizResult可以为空
    };
    return verifyResult;
  }
  onBizResultCallback(): void {
    console.log(111);
  }
  getInstance(instance: any): void {
    this.captcha = instance;
  }
  beforeUnmount(): void {
    document.getElementById("aliyunCaptcha-mask")?.remove();
    document.getElementById("aliyunCaptcha-window-popup")?.remove();
  }
  /**
   * 阿里滑块相关
   * =====end=====
   */

  /**
   * 腾讯滑块相关
   * =====start=====
   */
  txInit(): void {
    // 生成一个验证码对象
    // CaptchaAppId：登录验证码控制台，从【验证管理】页面进行查看。如果未创建过验证，请先新建验证。
    // 注意：不可使用客户端类型为小程序的CaptchaAppId，会导致数据统计错误。
    //callback：定义的回调函数
    this.captcha = new TencentCaptcha(this.appId, this.txCallback, {
      userLanguage: "zh-cn",
      showFn: (ret: any) => {
        const {
          duration, // 验证码渲染完成的耗时(ms)
          sid, // 链路sid
        } = ret;
      },
    });
  }
  txCallback(res: any): void {
    // ret         Int       验证结果，0：验证成功。2：用户主动关闭验证码。
    // ticket      String    验证成功的票据，当且仅当 ret = 0 时 ticket 有值。
    // CaptchaAppId       String    验证码应用ID。
    // bizState    Any       自定义透传参数。
    // randstr     String    本次验证的随机串，后续票据校验时需传递该参数。
    // verifyDuration     Int   验证码校验接口耗时（ms）。
    // actionDuration     Int   操作校验成功耗时（用户动作+校验完成）(ms)。
    // sid     String   链路sid。
    // res（用户主动关闭验证码）= {ret: 2, ticket: null}
    // res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
    // res（请求验证码发生错误，验证码自动返回trerror_前缀的容灾票据） = {ret: 0, ticket: "String", randstr: "String",  errorCode: Number, errorMessage: "String"}
    console.log("callback:", res);
    if (res.ret === 0) {
      const params = {
        captchaVerifyParam: res.ticket,
        randStr: res.randstr,
        uuid: this.uuid,
      };
      this.$emit("success", params);
    }
  }

  /**
   * 腾讯滑块相关
   * =====end=====
   */
  show(): void {
    console.log("handleShow");
    if (this.type === "ALIYUN") {
      this.captchaButton!.click();
    } else if (this.type === "TENCENT") {
      try {
        // 调用方法，显示验证码
        this.captcha.show();
      } catch (error) {
        // 加载异常，调用验证码js加载错误处理函数
        // 生成容灾票据或自行做其它处理
        var ticket = `trerror_1001_${this.appId}_${Math.floor(
          new Date().getTime() / 1000
        )}`;
        this.txCallback({
          ret: 0,
          randstr: "@" + Math.random().toString(36).substr(2),
          ticket: ticket,
          errorCode: 1001,
          errorMessage: "jsload_error",
        });
      }
    }
  }
}
