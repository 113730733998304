
























import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Col, Row, Button, NavBar, Grid, GridItem } from "vant";
import SelectSeatTitle from "@/components/SelectSeat/SelectSeatTitle.vue";
import SelectSeatDescription from "@/components/SelectSeat/SelectSeatDescription.vue";
import SelectSeatPrice from "@/components/SelectSeat/SelectSeatPrice.vue";
import SeatSelector from "@/components/SelectSeat/SeatSelector/SeatSelector.vue";
import UserCheck from "@/views/Login/UserCheck.vue";

@Component({
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    SelectSeatTitle,
    SelectSeatDescription,
    SelectSeatPrice,
    SeatSelector,
    UserCheck,
  },
})
export default class SelectSeat extends Mixins(Mixin) {
  isClick = false; // 防抖处理
  showPrice: good.ShowPriceDto = {
    captcha: undefined,
    priceDtos: [],
  }; // 演出票价信息
  cdnUrl = ""; // 座位底图的 CDN 地址
  isNeedSlider = false; // 是否需要滑块
  userCheck = null as null | UserCheck;

  get prices(): Array<good.PriceDto> {
    let priceDtos = this.showPrice.priceDtos;
    return priceDtos || [];
  }

  refresh(): void {
    let query = this.$route.query;
    let showId = Number(query.showId);
    let sectionId = Number(query.sectionId);
    let priceId = Number(query.priceId);
    let productId = query.productId;
    if (isNaN(showId) || isNaN(sectionId) || !productId) {
      this.$toast("非法请求");
    } else {
      this.SelectSeatModule.SET_showId(showId);
      this.SelectSeatModule.SET_sectionId(sectionId);
      this.SelectSeatModule.SET_productId(String(productId));
      if (!isNaN(priceId)) {
        this.SelectSeatModule.SET_highlightPriceId(priceId);
      }
    }
    let seatSelectorRow = (this.$refs["seat-selector-row"] as Row)
      .$el as HTMLElement;
    if (seatSelectorRow) {
      this.SelectSeatModule.SET_seatSelectorRowOffsetTop(
        seatSelectorRow.offsetTop
      );
    }
    // nextTick 是为了保证各个变量已经赋值完成后再执行
    this.$nextTick(() => {
      this.getIsNeedSlider();
      // 获取票价
      this.InterfaceCacheModule.getData({
        cacheKey: "show.getShowPrice",
        apiMethod: this.$api.goodApi.show.getShowPrice,
        params: [this.SelectSeatModule.showId],
        expireInterval: 28800, //缓存时间 480 分钟
        randInterval: 300, //5分钟随机时间
        callback: ({ data }) => {
          this.showPrice = data;
          const seatSelector = this.$refs["seat-selector"] as SeatSelector;
          if (seatSelector) {
            // 获取完票价再刷新选座组件，否则可能会出现座位没颜色的情况
            seatSelector.refresh();
          }
        },
      });
    });
  }
  getIsNeedSlider(): void {
    this.$api.goodApi.product.captchaType(
      this.SelectSeatModule.productId,
      ({ data }) => {
        this.isNeedSlider = data;
        if (data) {
          this.$nextTick(() => {
            this.userCheck = this.$refs["user-check"] as UserCheck;
          });
        }
      }
    );
  }
  submit(): void {
    if (!this.isNeedSlider) {
      this.getUserToken();
    } else {
      this.openUserCheck();
    }
  }
  openUserCheck(): void {
    if (this.userCheck) {
      this.userCheck.show();
    }
  }

  /**
   * 滑块成功
   */
  getUserToken(data?: order.CaptchaRequest): void {
    let selectedSeatIds = this.SelectSeatModule.selectedSeatIds;
    let surplus = this.SelectSeatModule.surplus;
    if (surplus > -1 && selectedSeatIds.length > surplus) {
      // 超出可购数量限制
      this.$toast({
        message: `单场限购${surplus}张`,
      });
    } else {
      const params = {
        captchaRequest: data,
        productId: this.SelectSeatModule.productId, // 产品 ID
        seatList: selectedSeatIds.map((seatId) => String(seatId)), // 座位 ID 列表
        sectionId: this.SelectSeatModule.sectionId, // 分区 ID
        showId: this.SelectSeatModule.showId, // 场次 ID
        distributionId: this.distributionChannelId,
        seriesId: this.distributionSeriesId,
      };
      if (this.AppSource == "harmonyOS") {
        if (!this.isClick) {
          this.isClick = true;
          window.setTimeout(() => {
            this.handleSubmitApi(params);
            this.isClick = false;
          }, 1000);
        }
      } else {
        this.handleSubmitApi(params);
      }
    }
  }
  handleSubmitApi(params: order.LockSeatChoose): void {
    this.$api.orderApi.ticketOrder.lockSeatChoose(
      params,
      ({ data }) => {
        this.SelectSeatModule.SET_submitLoading(false);
        // 将预订单 UUID 持久化，以便进入选座页时用来删除该预订单对应的锁定的座位
        this.DeleteLockSeatModule.SET_orderUuid_PERSIST(data);
        if (this.AppSource == "harmonyOS") {
          let params = {
            theaterId: this.TheaterModule.theaterId,
            uuid: this.DeleteLockSeatModule.orderUuid,
          };
          this.jsBridge("goConfirmOrder", params);
        } else {
          this.goToOrderConfirm(data);
        }
      },
      (error) => {
        this.SelectSeatModule.SET_submitLoading(false);
        this.$toast(`${error}`);
        if (error === "滑动验证结果不正确") {
          setTimeout(() => {
            this.openUserCheck();
          }, 1000);
        }
      }
    );
  }
}
